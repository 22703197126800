import { Controller } from "@hotwired/stimulus";
import { showToggleButton } from "../lib/togglePasswordVisibility";

// Connects to data-controller="password-visibility"
export default class extends Controller {
  static values = {
    showText: String,
    hideText: String,
  };

  connect() {
    this.#initPasswordToggle();
  }

  disconnect() {
    this.#unsetPasswordToggle();
  }

  #initPasswordToggle() {
    showToggleButton(this.element, {
      showText: this.showTextValue,
      hideText: this.hideTextValue,
    });
  }

  #unsetPasswordToggle() {
    this.element.nextElementSibling.remove();
  }
}
