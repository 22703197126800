import "./addToBasketFromIndex";
import "./minimumPriceNotReachedModal";
import "./turbo-actions";

import { initAxeptio } from "../plugins/init_axeptio";
import { initBasket } from "./basket/utils/initBasket";
import { initDeliverySelect } from "../plugins/delivery";

document.addEventListener("DOMContentLoaded", () => {
  initAxeptio();
  initBasket();
  initDeliverySelect();
});
