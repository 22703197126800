// Entry point for the build script in your package.json

require("@rails/ujs").start();

require("css3sidebar/dist/jquery.css3sidebar.js");

// Copy-pasted from lib, removed the NodeJS part which was not working for some reason
require("./plugins/jquery-form.js");

require("bootstrap");

require("@hotwired/turbo-rails");
Turbo.session.drive = false;

require("./controllers");
require("./lib");
