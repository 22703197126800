import { Controller } from "@hotwired/stimulus";
import { autofill } from "@mapbox/search-js-web";

// All the things that apply to elements on the page that are generated
// by external libs and we can't easily attach a stimulus controller to,
// because we don't have access to the generator
export default class extends Controller {
  connect() {
    this.#initAutofill();
  }

  disconnect() {
    this.#unsetAutofill();
  }

  #initAutofill() {
    this.filler = autofill({
      accessToken: process.env.MAPBOX_API_KEY,
      options: { country: "ch" },
    });

    // Mapbox adds a disgusting ` address-search` to the input names which breaks our forms
    document
      .querySelectorAll("input[name*=' address-search']")
      .forEach((input) => {
        input.setAttribute("name", input.name.replace(" address-search", ""));
      });
  }

  #unsetAutofill() {
    this.filler?.remove();
  }
}
