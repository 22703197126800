import { showOverlay, hideOverlay } from "../../../plugins/overlay";

export function startLoadingAnimation() {
  document.querySelectorAll("#basket #products").forEach((el) => {
    showOverlay(el);
  });
}

export function stopLoadingAnimation() {
  document.querySelectorAll("#basket #products").forEach((el) => {
    hideOverlay(el);
  });
}
