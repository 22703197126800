import { Controller } from "@hotwired/stimulus";

import owlCarousel from "owl.carousel2";

const WITH_ARROWS_OPTIONS = {
  loop: false,
  margin: 10,
  nav: true,
  autoWidth: true,
  touchDrag: false,
  navText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>",
  ],
};

const DEFAULT_OPTIONS = {
  responsive: {
    0: {
      items: 1,
      stagePadding: 0,
    },
    900: {
      stagePadding: 190,
      loop: true,
      margin: 10,
      items: 1,
      autoplay: true,
      autoplayTimeout: 7000,
      autoplayHoverPause: false,
    },
    1600: {
      stagePadding: 250,
      loop: true,
      margin: 10,
      items: 1,
      autoplay: true,
      autoplayTimeout: 7000,
      autoplayHoverPause: false,
    },
  },
};

// Connects to data-controller="carousel"
export default class extends Controller {
  static values = {
    withArrows: Boolean,
  };

  connect() {
    const opts = this.withArrowsValue ? WITH_ARROWS_OPTIONS : DEFAULT_OPTIONS;

    $(this.element).owlCarousel(opts);
  }
}
