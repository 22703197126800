import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="counter"
export default class extends Controller {
  static targets = ["total"];

  increment() {
    const total = parseInt(this.totalTarget.textContent);

    this.totalTarget.textContent = total + 1;
  }

  decrement() {
    const total = parseInt(this.totalTarget.textContent);

    if (total > 1) this.totalTarget.textContent = total - 1;
  }
}
